
// newsletter-form 
document.getElementById('newsletter-form').addEventListener('submit', function(event){
    event.preventDefault();
    submitNewsLetterForm();
});

function submitNewsLetterForm(){
    var email = document.getElementById('newsletter_email').value;
    var _token = document.getElementsByName('_token')[0].value;

    console.log( {
        email: email,
        name: name,
        _token: _token
    });
    axios.post('/newsletter', {
        email: email,
        name: name,
        _token: _token
    })
    .then(function (response) {
        console.log(response.data);
        document.getElementById('newsletter-form').reset();
        $.notify(response.data.message , 'success');
}   ) 
    .catch(function (error) {
        console.log(error);
        $.notify(error.response.data.message,  'danger');
    });
}